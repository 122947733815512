import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/functions";


export const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAH4YMhQxk08Zhe3WM7S8A8e0r7KGaWIkA",
    authDomain: "farmania-mw.firebaseapp.com",
    databaseURL: "https://farmania-mw.firebaseio.com",
    projectId: "farmania-mw",
    storageBucket: "farmania-mw.appspot.com",
    messagingSenderId: "491231575277",
    appId: "1:491231575277:web:0e5bbfd67687713cc2a0e4",
    measurementId: "G-E416YTQWCT"
});


export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const functions = firebase.functions();
// functions.useFunctionsEmulator( "http://localhost:5001");
export const timestamp = firebase.firestore.Timestamp;


import { auth } from "@/firebase";

export default {
    state: {
        user: null,
        status: null,
        error: null
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        removeUser(state) {
            state.user = null
        },
        setStatus(state, payload) {
            state.status = payload
        },
        setError(state, payload) {
            state.error = payload
        }
    },
    actions: {
        signUserUp({commit}, payload) {
            commit('setLoading', true)
            commit('clearError')
            const displayName = payload.displayName
            auth.createUserWithEmailAndPassword(payload.email, payload.password)
                .then(response  => {
                    commit('setLoading', false)
                    const newUser = {
                        id: response.user['uid'],
                        name: displayName,
                        email: response.user['email']
                    }
                    commit('setUser', newUser)
                    return response.user.updateProfile({ displayName })

                })
                .catch(error => {
                    commit('setLoading', false)
                    commit('setError', error)
                    console.log(error)
                })
        },
        signUserIn({commit}, payload) {
            commit('setLoading', true)
            commit('clearError')
            auth.signInWithEmailAndPassword(payload.email, payload.password)
                .then(response => {
                    commit('setLoading', false)
                    const newUser = {
                        id: response.user['uid'],
                        name: response.user['displayName'],
                        email: response.user['email'],
                    }
                    commit('setUser', newUser)
                })
                .catch(error => {
                    commit('setLoading', false)
                    commit('setError', error)
                    // console.log(error)
                    alert(error.toLocaleString())
                })
        },
        resetPasswordWithEmail({commit}, payload) {
            const email = payload
            commit('setLoading', true)
            auth.sendPasswordResetEmail(email)
                .then(() => {
                    commit('setLoading', false)
                    console.log('email sent')
                })
                .catch(error => {
                    commit('setLoading', false)
                    commit('setError', error)
                    console.log(error)
                })
        },
        logOut ({commit}) {
            auth.signOut()
                .then(() => {
                    console.log('user out')
                    commit('setUser', null)
                })
                .catch(error => {
                    console.log(error)
                    commit('setUser', null)
                })

        }
    },
    getters: {
        user (state) {
            return state.user
        },
        status (state) {
            return state.status
        },
        error(state) {
            return state.error
        },
        displayName(state) {
            return state.user.displayName
        },
        email(state) {
            return state.user.email
        }
    }
}

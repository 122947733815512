import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/user/index';
import theme from '@/store/theme/index';
import shared from '@/store/shared/index';
// import pharmacies from "@/store/modules/pharmacies";
// import pharmacyProducts from "@/store/modules/pharmacyProducts";
// import cs_cart_products from "@/store/modules/cs_cart_products";
// import users from "@/store/users";
// import createPersistedState from "vuex-persistedstate";
// import VuexEasyFirestore from 'vuex-easy-firestore';

Vue.use(Vuex)

// import configuration
// import {Firebase, initFirebase } from "@/firebase";

// const easyFirestore = VuexEasyFirestore([
//         pharmacies,
//         pharmacyProducts,
//         users,
//         cs_cart_products],
//     { logging: true, FirebaseDependency: Firebase }
// )


const storeData = {
    // plugins: [easyFirestore, createPersistedState()],

    modules: {
        theme: theme,
        user: user,
        shared: shared
    }
}

const store = new Vuex.Store(storeData);

// /// Init Firebase
// initFirebase().catch(error => {
//     console.log(error)
// })

export default store;

import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import AuthGuard from "@/auth_guard";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    // This is for the scroll top when click on any router link
    routes: [
        {
            path: '/',
            redirect: 'pharmacies/singleProductInput',
            component: () => import('./layouts/base-layout/BaseLayout'),
            children: [
                {
                    name: 'Dashboard',
                    path: 'dashboard',
                    component: () => import('@/views/dashboard/Dashboard'),
                    beforeEnter: AuthGuard,
                },
                {
                    name: 'SingleProductInput',
                    path: 'pharmacies/singleProductInput',
                    component: () => import('@/views/pharmacies/SingleProductInput'),
                    beforeEnter: AuthGuard
                },


                {
                    name: 'Products',
                    path: 'pharmacies/products',
                    component: () => import('@/views/pharmacies/CommerceProducts'),
                    beforeEnter: AuthGuard
                },


                {
                    name: 'PharmacyProducts',
                    path: 'pharmacies/:id/products',
                    component: () => import('@/views/pharmacies/Products'),
                    beforeEnter: AuthGuard,
                },
                {
                    name: 'Product_Detail',
                    path: 'product/:id',
                    component: () => import('@/views/pharmacies/ProductDetail'),
                    // beforeEnter: AuthGuard,
                },
                {
                    name: 'CS-CART_PRODUCTS',
                    path: 'pharmacies/:id/cs-cart_products',
                    component: () => import('@/views/pharmacies/CS-CART_Products'),
                    beforeEnter: AuthGuard,
                },
                {
                    name: 'CS_CART_Detail',
                    path: 'cs-cart_products/:id',
                    component: () => import('@/views/pharmacies/CS_CART_Detail'),
                    beforeEnter: AuthGuard,
                }
            ]
        },
        {
            path: '/authentication',
            component: () => import('@/layouts/auth-layout/AuthLayout'),
            children: [
                {
                    name: 'FullLogin',
                    path: 'fulllogin',
                    component: () => import('@/views/authentication/FullLogin'),
                },
                {
                    name: 'Error',
                    path: 'error',
                    component: () => import('@/views/authentication/Error'),
                },
            ]
        },
    ],
})

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Vuebar from 'vuebar'
import './plugins/base'
import VueSkycons from 'vue-skycons';
import AxiosPlugin from 'vue-axios-cors';
import UUID from "vue-uuid";
import ActivityIndicator from 'vue-activity-indicator';
import VueNumeric from 'vue-numeric';
import { Simplert } from 'vue2-simplert-plugin';
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css';



Vue.use(VueSkycons, {
    color: '#ff7600'
});

import VueFirestore from 'vue-firestore';
Vue.config.productionTip = false
Vue.use(Vuebar);
Vue.use(AxiosPlugin);
Vue.use(UUID);
Vue.use(VueFirestore);
Vue.use(ActivityIndicator);
Vue.use(require('vue-moment'));
Vue.use(VueNumeric);
Vue.use(Simplert);

Vue.prototype.$DEBUG_MODE = false;

Vue.prototype.$BASEURL = 'https://fm-data-api.onrender.com/';
// Vue.prototype.$BASEURL = 'http://localhost:3001/';

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
